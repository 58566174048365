<template>
  <div class="my-second help bgcolor">
      <Head :title="$t('info.get_two_psd')" :show="true" ></Head>
      <div class="common-box help-box">
          
        <van-form ref="form" @submit="gotoNext" >
            <van-cell-group class="form-box ">
            <van-field
                    v-model="obj.area"
                    type="text"
                    name="area"
                    :label="$t('info.c_area')"
                    :rules="[]"
                    right-icon="arrow"
                    readonly
                    class="van-nohidden van-countrynum"
                    @click.stop="showSome"
                >
                    <template #input>
                        <div class="tel-qu width100">
                            <AreaNum ref="choosenum" @excountry="getCountryItem"  country-code="th"/>
                            <span class="my-country">{{obj.country_name}}</span>
                        </div>
                    </template>
                </van-field>
                <van-field 
                    type="tel"  
                    :label="$t('login.phone')" 
                    name="phone"
                    v-model="obj.phone" 
                    :placeholder="$t('info.input_phone_num')" clearable 
                    :rules="[{ required: true, message:$t('login.empty_phone')},{pattern:phoneReg,message:$t('login.err_phone')}]"
                />
                <van-field
                    v-model="obj.code"
                    type="text"
                    name="code"
                    :label="$t('login.captcha')"
                    maxlength="6"
                    :placeholder="$t('login.enter')+$t('login.captcha')"
                    :rules="[{ required: true, message:$t('login.required_captcha')},{pattern:smsReg,message:$t('login.ture_captcha'),trigger:'onBlur'}]"
                >
                    <template #button>
                        <SmsDemo :phone="obj.phone" type="1"  :area="obj.area" ></SmsDemo>
                    </template>
                </van-field>
            </van-cell-group>
            </van-form>
            <van-button class="confirm-btn" @click="onFinish" >{{$t('info.next_tap')}}</van-button>
          </div>
      </div>
</template>

<script>
import {phoneReg,smsReg} from "@/common/validate"
import {areaCode} from "@/common"
export default {
    name:"forgotpass",
    data(){
        return {
            obj:{
                area:'66',
                ...areaCode
            },
            phoneReg,
            smsReg,
        }
    },
    methods:{
        getCountryItem(data){
            this.obj.area = data.dialCode;
            this.obj.country_name = data.name;
        },
        onFinish(){
           this.$refs.form.submit();
        },
         showSome(){
            this.$refs.choosenum.hideSubMenu = false;   // 显示国旗 
        },
        gotoNext(value){
            console.log(value)
            this.$ajax.verifyresetpincode({
                code:value.code,
                phone:value.phone,
                area:this.obj.area
            }).then(res=>{
                if(res.code==200){
                    
                    this.$router.push({
                        name:'mine-resetpindone'
                    })
                }
            })
            
        },
    },
}
</script>
